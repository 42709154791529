<template>
    <el-container>
        <!-- 头部 -->
        <el-header height="auto" class="header"><shop-header /></el-header>
        <el-main class="content">
            <!-- 轮播图 -->
            <div class="shop-banner">
                <el-carousel height="406px">
                    <el-carousel-item v-for="item in adList" :key="item.adv_id">
                        <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <transition name="slide"><router-view /></transition>
        </el-main>
        <!-- 底部 -->
        <el-footer><ns-footer /></el-footer>
    </el-container>
</template>
<script>
import ShopHeader from "./components/ShopHeader"
import NsHeader from "./components/NsHeader"
import NsFooter from "./components/NsFooter"
import { adList } from "@/api/website";

export default {
    created() {
        this.getAdList();
    },
    data: () => {
        return {
            adList: []
        }
    },
    mounted() { },
    computed: {},
    methods: {
        getAdList() {
            adList({ keyword: "NS_PC_SHOP_INDEX" })
                .then(res => {
                    this.adList = res.data.adv_list;
                    for (let i = 0; i < this.adList.length; i++) {
                        if (this.adList[i].adv_url)
                            this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
                    }
                })
                .catch(err => err);
        },
    },

    watch: {
        $route(curr) {
            this.id = curr.params.pathMatch;
            this.getAdList();
        }
    },
    components: {
        ShopHeader,
        NsFooter
    }
}
</script>
<style lang="scss" scoped>
.header {
    padding: 0;
}

.shop-banner {
    text-align: center;
    width: 100%;
    // border: 1px solid red;
    box-sizing: border-box;
}

.content {
    max-width: $width;
    // max-width: 100%;
    // width: 100%;
    margin: 0 auto;
    padding: 0;
}

.el-footer {
    padding: 0;
    height: auto !important;
    background-color: #fff;
    padding-top: 45px;
}

.el-main {
    border-top: none;
}
</style>
