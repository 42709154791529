var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"header"},[_c('ns-header-top'),_c('ns-header-mid'),_c('div',{staticClass:"shop-header"},[_c('div',{staticClass:"nav"},[_c('div',{staticClass:"shop-sort",on:{"mouseover":_vm.shopHover,"mouseleave":_vm.shopLeave}},[_c('router-link',{attrs:{"to":'/shop_list?site_id=' + _vm.site_id}},[_vm._v("All product categories")]),_c('i',{staticClass:"iconfont iconweibiaoti35"})],1),_c('div',{staticClass:"shop-list",class:_vm.forceExpand || _vm.isShopHover || _vm.isIndex ? 'shop-list-active border' : 'shadow',on:{"mouseover":function($event){$event.stopPropagation();return _vm.shopHover.apply(null, arguments)},"mouseleave":function($event){$event.stopPropagation();return _vm.shopLeave.apply(null, arguments)}}},_vm._l((_vm.goodsCategoryTree),function(item,index){return _c('div',{key:index,staticClass:"list-item",on:{"mouseover":function($event){_vm.selectedCategory = item.category_id},"mouseleave":function($event){_vm.selectedCategory = -1}}},[_c('router-link',{staticStyle:{"color":"#fff"},attrs:{"to":{ path: '/shop_list', query: { site_id: _vm.site_id, category_id: item.category_id, level: item.level } }}},[_c('img',{staticClass:"category-img",attrs:{"src":_vm.$util.img(item.image)}}),_vm._v(" "+_vm._s(item.category_name)+" ")]),(item.child_list)?_c('div',{staticClass:"cate-part",class:{ show: _vm.selectedCategory == item.category_id }},[_c('div',{staticClass:"cate-part-col1"},[_c('div',{staticClass:"cate-detail"},_vm._l((item.child_list),function(second_item,second_index){return _c('dl',{key:second_index,staticClass:"cate-detail-item"},[_c('dt',{staticClass:"cate-detail-tit",on:{"click":function($event){return _vm.test(second_item)}}},[_c('router-link',{attrs:{"to":{
											path: '/shop_list',
											query: { site_id: _vm.site_id, category_id: second_item.category_id, level: second_item.level }
										}}},[_c('img',{staticClass:"category-img",attrs:{"src":_vm.$util.img(second_item.image)}}),_vm._v(" "+_vm._s(second_item.category_name)+" "),(second_item.child_list)?_c('i',{staticClass:"el-icon-arrow-right",attrs:{"aria-hidden":"true"}}):_vm._e()])],1),(second_item.child_list)?_c('dd',{staticClass:"cate-detail-con"},_vm._l((second_item.child_list),function(third_item,third_index){return _c('router-link',{key:third_index,attrs:{"to":{
												path: '/shop_list',
												query: { site_id: _vm.site_id, category_id: third_item.category_id, level: third_item.level }
											}}},[_vm._v(" "+_vm._s(third_item.category_name)+" ")])}),1):_vm._e()])}),0)])]):_vm._e()],1)}),0),_c('nav',[_c('ul',_vm._l((_vm.navList),function(nav_item){return _c('li',{key:nav_item.id,class:nav_item.url == _vm.navSelect ? 'router-link-active' : '',on:{"click":function($event){return _vm.navUrl(nav_item.id)}}},[_c('span',[_vm._v(_vm._s(nav_item.nav_title))])])}),0)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }